module.exports = PaginationTools = {
    handleAjaxPaginationEvents : (container, identifier, callback) => {
        let paginationElement = jQuery(identifier);

        if (paginationElement !== undefined && paginationElement.hasClass('ajax-pagination')) {
            let paginationData = paginationElement.data();
            let paginationParams = {};
            let targetUrl = undefined;

            for (entry in paginationData) {
                if (entry == 'target') {
                    targetUrl = `${paginationData[entry]}`;
                } else {
                    paginationParams[entry] = `${paginationData[entry]}`;
                }
            }
            
            if (targetUrl !== undefined) {
                let currentPage = undefined;
                let currentPageLink = undefined;
                paginationElement.find('.page-link').each((i, item) => {
                    let pageLink = jQuery(item);

                    if (pageLink.parent().hasClass('active')) {
                        currentPage = pageLink.parent();
                        currentPageLink = pageLink;
                    }

                    pageLink.on('click', event => {
                        event.preventDefault();
                        event.stopImmediatePropagation();
                        event.stopPropagation();

                        paginationParams['page'] = pageLink.data('page');

                        jQuery.ajax(targetUrl, {
                            type : "POST",
                            dataType : "json",
                            data : JSON.stringify(paginationParams),
                            beforeSend : () => {},
                            success: (response, status) => {
                                if (status == 'success') {
                                    if (response.type == 'success') {
                                        callback(container, response.pagination, response.translations);
                                        currentPage.removeClass('active');
                                        currentPage = pageLink.parent();
                                        currentPage.addClass('active');
                                        currentPageLink = pageLink;
                                    }
                                }
                            },
                            error : (response, status, error) => {
                                console.log(response)
                                console.log(status)
                                console.log(error)
                            }
                        });
                    });
                });
            }
        }
    },

    renderPagination : (paginationData, translations) => {
        if (paginationData !== undefined && paginationData.page_count > 1) {
            let paginationWrapper = jQuery('<div>');
            paginationWrapper.addClass('pagination').addClass('ajax-pagination');

            if (paginationData.ajax_paginator.pagination.ajax_parameters !== undefined) {
                Object.keys(paginationData.ajax_paginator.pagination.ajax_parameters).forEach((key) => {
                    paginationWrapper.attr(key, paginationData.ajax_paginator.pagination.ajax_parameters[key]);
                });
            }

            paginationWrapper.attr('data-target', paginationData.ajax_paginator.pagination.ajax_retrieve_route);

            let paginationListWrapper = jQuery('<div>').addClass('pagination-list');

            if (paginationData.prevous_page !== undefined) {
                paginationListWrapper
                    .append(
                        jQuery('<div>').addClass('page-item')
                            .append(
                                jQuery('<div>').addClass('page-link')
                                    .attr('data-page', paginationData.prevous_page)
                                    .text('\u00bb ' + translations.previous)
                            )
                    );
            } else {
                paginationListWrapper
                    .append(
                        jQuery('<div>').addClass('page-item').addClass('disabled')
                            .append(
                                jQuery('<div>').addClass('page-link')
                                    .text('\u00bb ' + translations.previous)
                            )
                    );
            }

            if (paginationData.start_page > 1) {
                paginationListWrapper.append(
                    jQuery('<div>').addClass('page-item')
                        .append(
                            jQuery('<div>').addClass('page-link')
                                .attr('data-page', 1).text(1)
                        )
                );

                if (paginationData.start_page == 3) {
                    paginationListWrapper.append(
                        jQuery('<div>').addClass('page-item')
                            .append(
                                jQuery('<div>').addClass('page-link')
                                    .attr('data-page', 2).text(2)
                            )
                    );
                } else if (paginationData.start_page != 2) {
                    paginationListWrapper.append(
                        jQuery('<div>').addClass('page-item').addClass('disabled')
                            .append(
                                jQuery('<div>').addClass('page-link')
                                    .append(
                                        jQuery('<span>')
                                            .append(
                                                jQuery('<i>').addClass('fa-solid').addClass('fa-ellipsis')
                                            )
                                    )
                            )
                    );
                }
            }

            for (let page = 1; page <= paginationData.page_range; ++page) {
                let pageItem = jQuery('<div>').addClass('page-item');
                if (page == paginationData.current_page) {
                    pageItem.addClass('active')
                }

                pageItem.append(
                    jQuery('<div>').addClass('page-link')
                        .attr('data-page', page).text(page)
                );

                paginationListWrapper.append(pageItem);
            }

            if (paginationData.page_count > paginationData.end_page) {
                if (paginationData.page_count > (paginationData.end_page + 1)) {
                    let pageItem = jQuery('<div>').addClass('page-item');
                    let pageLink = jQuery('<div>').addClass('page-link');

                    if (paginationData.page_count > (paginationData.end_page + 2)) {
                        pageItem.addClass('disabled');
                        pageLink.append(
                            jQuery('<span>').append(
                                jQuery('<i>').addClass('fa-solid').addClass('fa-ellipsis')
                            )
                        );
                    } else {
                        pageLink.attr('data-page', (paginationData.page_count - 1))
                            .text(paginationData.page_count - 1);
                    }

                    pageItem.append(pageLink);
                    paginationListWrapper.append(pageItem);
                }
            }

            let pageItem = jQuery('<div>').addClass('page-item');
            let pageLink = jQuery('<div>').addClass('page-link');

            if (paginationData.next_page != undefined) {
                pageLink.attr('data-page', paginationData.next_page);
            } else {
                pageItem.addClass('disabled');
            }

            pageLink.text(translations.next + '\u00bb');

            paginationListWrapper.append(pageItem.append(pageLink));
            paginationWrapper.append(paginationListWrapper);

            return paginationWrapper;
        }
        return '';
    }
};
