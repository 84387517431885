// loads the jquery package from node_modules

require('bootstrap');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

global.moment = require('moment');
require('bootstrap-datepicker');

require('../tools/js/tools.js');
require('../tools/js/menu.js');
require('../tools/js/pagination.js');

import './sass/site-app.scss';

jQuery(document).ready(() => {
    JeproTools.renderNavigationTabs();
    JeproTools.customizeSelectField();

    let datepickerFields = jQuery('.datepicker-widget');
    if(datepickerFields !== undefined) {
        datepickerFields.each((index, field) => {
            jQuery(field).datepicker({
                format: 'yyyy-MM-dd'
            })
        })
    }

    let jeprodevMenuWrapper = jQuery("#jeprodev_menu_wrapper");

    if(jeprodevMenuWrapper !== undefined) {
        jeprodevMenuWrapper.DropDownMenu({
            container : '#jeprodev_menu_list_wrapper',
            wrapper: '#company_menu_list',
            behavior: 'mouse_over',
            show_active_sub_items: '0',
            test_overflow: '1',
            effect: {type: 'push_down'},
            open: {type: 'open'},
            time_in: 0, time_out: 500,
            layout: {
                orientation: 'horizontal',
                position: 'top_fixed',
                submenu: {
                    width: 160
                }
            },
            direction: {
                orientation: 'normal',
                offset: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                }
            },
            fx: {
                duration: 500,
                transition: 'linear'
            },
            responsive: {
                toggle: '#menu_toggle_btn',
                menu: undefined,
                width: 767
            }
        });
    }

    let personalBirthDay = jQuery('.user-birthday');

    if (personalBirthDay !== undefined) {
        personalBirthDay.each((index, item) => {
            let locale = JeproTools.getRequestLocale();
            locale = (locale !== undefined && locale.length === 5) ? locale.substr(0, 2) : 'en';

            jQuery(item).datepicker({
                format : 'yyyy-MM-dd',
                endDate : '-18y',
                maxDate : '-18y',
                weekStart : 1,
                autoclose: true,
                language: locale
            });
        });
    }
});
