(function($){
    $.fn.DropDownMenu = function(opts){
        let defaultMenuParams = {
            container: undefined,
            wrapper : undefined,
            behavior :'mouse_over',
            show_active_sub_items : '0',
            test_overflow : '1',
            effect : { type : 'push_down' },
            open : { type : 'open' },
            time_in:0,
            time_out:500,
            layout:{
                orientation : 'horizontal',
                position : 'top_fixed',
                submenu : {
                    width : 160
                }
            },
            direction : {
                orientation: 'normal',
                offset : {
                    top: 0,
                    right: 0,
                    bottom : 0,
                    left : 0
                }
            },
            fx : {
                duration : 500,
                transition : 'linear'
            },
            responsive: {
                toggle: '#menu_toggle_btn',
                menu: '#jeprodev_menu_list',
                width: 767
            },
            status : [],
        };

        /** calling default options **/
        let options = jQuery.extend(true, defaultMenuParams, opts);
        let horizontalMenuWrapper = undefined;
        let horizontalMenuObject = this;

        return horizontalMenuObject.each(function(){ initializeMenu(); });

        function initializeMenu(){
            if(options.wrapper !== undefined){
                horizontalMenuWrapper = $(options.wrapper);
                if(horizontalMenuWrapper !== undefined){
                    if(jQuery(window).width() <= options.responsive.width){
                        renderMobileLayout();
                    }else{
                        renderDesktopLayout();
                    }
                }
            }
        }

        function renderDesktopLayout(){
            let menuList = jQuery(options.wrapper, horizontalMenuObject);
            let menuParentElements; 

            if(options.effect.type === 'push_down'){
                jQuery('li.level-1', menuList).each(function(index, elt){
                    elt = jQuery(elt); 
                    if(elt.hasClass('parent')){
                        elt.data('status', 'opened');
                        elt.submenu = jQuery('> .menu-push-down > .menu-float',elt); 
                        hideSubMenu(elt);
                    }
                    elt.data('status', 'closed');
                });
                menuParentElements = jQuery('li.level-1.parent', menuList);
            }

            if(menuParentElements !== undefined && menuParentElements.length > 0){
                menuParentElements.each(function(index, elt){
                    elt = jQuery(elt); 
                    if(!elt.hasClass('no-drop-down')){ 
                        //manage item level
                        if(elt.hasClass('level-1')){ elt.data('level', 1); }

                        jQuery('li.parent', elt).each(function (j, child){
                            jQuery(child).data('level', elt.data('level') + 1);
                        });

                        //manage sub-menus
                        if(options.effect.type === 'push_down'){
                            elt.submenu = jQuery('> .menu-push-down > .menu-float', elt).eq(index);
                            elt.submenu.find('> .sub-menu-drop-main').css('width', 'inherit')
                                .css('overflow', 'hidden');
                        }else{
                            elt.submenu = jQuery('> .menu-float', elt);
                            elt.submenu.css('position', 'absolute');
                        }

                        elt.submenu.css('width', options.layout.submenu.width + 'px');
        
                        if(options.open.type === 'no_effect' || options.open.type === 'open' || options.open.type === 'slide'){
        
                        }else{
                            elt.submenu.css('display', 'block');
                        }

                        //manage active sub menus
                        if ((options.show_active_sub_items === '1' && elt.hasClass('active')) || elt.hasClass( 'menu-open')) {
                            if (elt.hasClass('full_width')) {
                                elt.submenu.css('display', 'block').css('left', '0');
                            } else {
                                elt.submenu.css('display', 'block');
                            }
                            elt.submenu.css('max-height', '');
                            elt.submenu.show();
                        }

                        // manage inverse direction
                        if (options.direction.orientation === 'inverse' && elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                            elt.submenu.css('bottom', options.direction.offset.bottom + 'px');
                        }
        
                        if (options.direction.orientation === 'inverse' && elt.hasClass('level-1') && options.layout.orientation === 'vertical') {
                            elt.submenu.css('right', options.direction.offset.right + 'px');
                        }
        
                        if (options.behavior === 'click_close') {
        
                        } else if (options.behavior === 'click') {
        
                        } else {
                            elt.on('mouseenter', function () {
                                elt.submenu = jQuery('> .menu-push-down > .menu-float', elt);//.eq(index);
        
                                if (options.effect.type === 'push_down') {
                                    jQuery('li.level-1.parent', menuList).each(function (j, elt2) {
                                        elt2 = jQuery(elt2);
                                        if (elt.prop('class') !== elt2.prop('class')) {
                                            elt2.submenu = jQuery('> .menu-push-down > .menu-float', elt2).eq(j);
                                            hideSubMenu(elt2);
                                        }
                                    });
                                } else {
                                    if (options.test_overflow === '1') {
                                        testMenuOverflow(elt);
                                    }
                                }
                                showSubMenu(elt);
                            });
        
                            if (options.effect.type === 'push_down') {
                                horizontalMenuObject.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                                elt.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                            } else {
                                elt.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                            }                            
                        }
                    
                    }
                });
            }
        }

        function renderMobileLayout(){
            let menuList = jQuery(options.wrapper, horizontalMenuObject);
            let toggleBtn = jQuery(options.responsive.toggle);

            if(toggleBtn !== undefined){
                toggleBtn.data("visible", 0);
                toggleBtn.on('click', function(evt){
                    if(toggleBtn.data('visible') === 0){
                        toggleBtn.data('visible', 1);
                        showMenuList(menuList);
                    }else{
                        toggleBtn.data('visible', 0);
                        hideMenuList(menuList);
                    }
                });
            }
            

            if(options.effect.type === 'push_down'){
                jQuery('li.level-1', menuList).each(function(index, elt){
                    elt = jQuery(elt); 
                    if(elt.hasClass('parent')){
                        elt.data('status', 'opened');
                        elt.submenu = jQuery('> .menu-push-down > .menu-float',elt); 
                        hideSubMenu(elt);
                    }
                    elt.data('status', 'closed');
                });
                menuParentElements = jQuery('li.level-1.parent', menuList);
            }

            if(menuParentElements !== undefined && menuParentElements.length > 0){
                menuParentElements.each(function(index, elt){
                    elt = jQuery(elt); 
                    if(!elt.hasClass('no-drop-down')){ 
                        //manage item level
                        if(elt.hasClass('level-1')){ elt.data('level', 1); }

                        jQuery('li.parent', elt).each(function (j, child){
                            jQuery(child).data('level', elt.data('level') + 1);
                        });

                        //manage sub-menus
                        if(options.effect.type === 'push_down'){
                            elt.submenu = jQuery('> .menu-push-down > .menu-float', elt).eq(index);
                            elt.submenu.find('> .sub-menu-drop-main').css('width', 'inherit')
                                .css('overflow', 'hidden');
                        }else{
                            elt.submenu = jQuery('> .menu-float', elt);
                            elt.submenu.css('position', 'absolute');
                        }

                        elt.submenu.css('width', options.layout.submenu.width + 'px');
        
                        if(options.open.type === 'no_effect' || options.open.type === 'open' || options.open.type === 'slide'){
        
                        }else{
                            elt.submenu.css('display', 'block');
                        }

                        //manage active sub menus
                        if ((options.show_active_sub_items === '1' && elt.hasClass('active')) || elt.hasClass( 'menu-open')) {
                            if (elt.hasClass('full_width')) {
                                elt.submenu.css('display', 'block').css('left', '0');
                            } else {
                                elt.submenu.css('display', 'block');
                            }
                            elt.submenu.css('max-height', '');
                            elt.submenu.show();
                        }

                        // manage inverse direction
                        if (options.direction.orientation === 'inverse' && elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                            elt.submenu.css('bottom', options.direction.offset.bottom + 'px');
                        }
        
                        if (options.direction.orientation === 'inverse' && elt.hasClass('level-1') && options.layout.orientation === 'vertical') {
                            elt.submenu.css('right', options.direction.offset.right + 'px');
                        }
        
                        if (options.behavior === 'click_close') {
        
                        } else if (options.behavior === 'click') {
        
                        } else {
                            elt.on('mouseenter', function () {
                                elt.submenu = jQuery('> .menu-push-down > .menu-float', elt);//.eq(index);
        
                                if (options.effect.type === 'push_down') {
                                    jQuery('li.level-1.parent', menuList).each(function (j, elt2) {
                                        elt2 = jQuery(elt2);
                                        if (elt.prop('class') !== elt2.prop('class')) {
                                            elt2.submenu = jQuery('> .menu-push-down > .menu-float', elt2).eq(j);
                                            hideSubMenu(elt2);
                                        }
                                    });
                                } else {
                                    if (options.test_overflow === '1') {
                                        testMenuOverflow(elt);
                                    }
                                }
                                showSubMenu(elt);
                            });
        
                            if (options.effect.type === 'push_down') {
                                horizontalMenuObject.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                                elt.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                            } else {
                                elt.on('mouseleave', function () {
                                    hideSubMenu(elt);
                                });
                            }                            
                        }
                    
                    }
                });
            }

            menuList.on("mouseleave", function(){ 
                toggleBtn.data('visible', 0);
                hideMenuList(menuList);             
            });
            hideMenuList(menuList);
        }

        function hideSubMenu(elt){
            if (options.effect.type === 'push_down' && elt.data('status') !== 'closing') {
                closeMenu(elt);
            }else if (options.effect.type !== 'push_down') {
                elt.css('z-index', 20000);
                if(typeof elt.submenu !== 'undefined') {
                    elt.submenu.css('z-index', 20001);
                }
                clearTimeout(elt.time_out);
                elt.time_out = setTimeout(function (){ closeMenu(elt); }, options.time_out);
            }
        }

        function showSubMenu(elt){
            elt.css('z-index', 15000);
            elt.submenu.css('z-index', 15000);
            clearTimeout(elt.time_out);
            elt.time_out = setTimeout(function(){ openMenu(elt); }, options.time_in);
        }  

        function hideMenuList(list){
            if(list !== undefined){
                list.stop(true, true);
                list.data('status', 'closing');
                list.hide(0, {
                    complete: function(){
                        list.data('status', 'closed');
                    }
                });
            }
        }

        function showMenuList(list){
            if(list !== undefined){ 
                if(options.effect.type === 'push_down' && list.data('status') !== 'opening'){
                    list.data('status', 'opening');
                    clearTimeout(list.time_out);
                    list.time_out = setTimeout(function(){
                        //let subMenu = jQuery(elt.submenu);

                        if(list.data('status') !== 'opened'){
                            list.stop(true, true);
                        }

                    }, options.time_in);
                }
                        
                let fxDuration = options.fx.duration;
                let fxTransition = 'swing';

                switch (options.open.type) {
                    case 'open' : 
                    default:
                        
                        list.stop();
                        list.show(fxDuration);
                        list.animate({'max-height' : list.height()}, {
                            duration : fxDuration, queue : false, easing: fxTransition, 
                            complete : function(){
                                jQuery(this).css('max-height', '');
                                list.data('status', 'opened');
                            }
                        });
                }
            }
        }       

        function testMenuOverflow(elt){
            if (elt.hasClass('full_width')) {
                return;
            }
            let pageWidth = jQuery(document.body).outerWidth();
            let eltPosition = elt.offset().left + elt.outerWidth() + elt.submenu.width();

            if (eltPosition > pageWidth) {
                if (elt.data('level') === 1) {
                    elt.submenu.css('right', '0px');
                } else {
                    elt.submenu.css('right', options.layout.submenu.width + 'px');
                }
                elt.submenu.css('marginRight', '0px');
                elt.submenu.addClass('fix_right');
            }
        }

        function closeMobileMenu(elt){
            if(elt.submenu !== undefined){ elt.submenu.stop(true, true); }
            options.status[elt.data('level')] = '';
            elt.data('status', 'closing');
            switch (options.open.type) {
                case 'open':
                    if(elt.submenu !== undefined){
                        elt.submenu.close();
                        options.status[elt.data('level')] = '';
                        elt.submenu.css('overflow', 'hidden');
                        elt.data('status', 'closing');
                        if(elt.hasClass('level-1')){
                            elt.submenu.css('overflow', 'hidden')
                                .css('max-width', elt.submenu.width())
                                .animate({'max-width': 0}, {
                                    duration: options.fx.duration, queue: false,
                                    easing: options.fx.transition,
                                    complete: function(){
                                        elt.submenu.css('max-width', '');
                                        elt.submenu.css('display', 'none');
                                        elt.submenu.css('position', 'absolute');
                                        options.status[elt.data('level')] = '';
                                        elt.data('status', 'closed');
                                    }
                                });
                        }
                    }
                    break;
            }
        }

        function closeMenu(elt) {
            if(typeof elt.submenu !== 'undefined'){ elt.submenu.stop(true, true); }
            options.status[elt.data('level')] = '';
            elt.data('status', 'closing');
            switch (options.open.type) {
                case 'no_effect' :
                    elt.submenu.css('display', 'none');
                    options.status[elt.data('level')] = '';
                    elt.data('status', 'closed');
                    break;
                case 'fade' :
                    elt.submenu.fadeOut(options.fx.duration, options.fx.transition, {
                        complete: function () {
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'closed');
                        }
                    });
                    elt.data('status', 'closed');
                    break;
                case 'slide' :
                    if (elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                        elt.submenu.css('max-height', '');
                    } else {
                        elt.submenu.css('max-width', '');
                    }
                    elt.submenu.css('display', 'none');
                    elt.submenu.css('position', 'absolute');
                    options.status[elt.data('level')] = '';
                    elt.data('status', 'closed');
                    break;
                case 'open' :
                    if(typeof elt.submenu !== 'undefined') {
                        elt.submenu.stop();
                        options.status[elt.data('level')] = '';
                        elt.submenu.css('overflow', 'hidden');
                        elt.data('status', 'closing');
                        if (elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                            elt.submenu.css('overflow', 'hidden').css('max-height', elt.submenu.height()).animate({'max-height': 0}, {
                                duration: options.fx.duration, queue: false, easing: options.fx.transition, complete: function () {
                                    elt.submenu.css('max-height', '');
                                    elt.submenu.css('display', 'none');
                                    elt.submenu.css('position', 'absolute');
                                    options.status[elt.data('level')] = '';
                                    elt.data('status', 'closed');
                                }
                            });
                        } else {
                            elt.submenu.css('max-width', '');
                            elt.submenu.css('display', 'none');
                            elt.submenu.css('position', 'absolute');
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'closed');
                        }
                    }
                    break;
                case 'drop' :
                default :
                    elt.submenu.hide(0, {
                        complete: function () {
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'closed');
                        }
                    });
                    elt.data('status', 'closed');
                    break;
            }
        }        

        function openMenu(elt){
            let subMenu = jQuery(elt.submenu);
            let fxDuration = options.fx.duration;
            let fxTransition = 'swing';

            let slideContainer;
            if (elt.data('status') === 'opened' || (status[elt.data('level') - 1] === 'showing' && options.open.type === 'drop')) return;

            subMenu.stop(true, true);
            if(elt.hasClass('level-1')) {
                subMenu.css('left', 'auto');
            }else{
                let newPosition = elt.parent().width();
                subMenu.css('left', newPosition + 'px');
                subMenu.css('margin-left', 0);
            }

            elt.submenu.css('display', 'block');

            if (options.effect.type === 'push_down') {
                elt.submenu.css('position', 'relative');
            }
            if (options.open.type !== 'no_effect') {
                options.status[elt.data('level')] = 'showing';
            }

            switch (options.open.type) {
                case 'slide' :
                    if (elt.data('status') === 'opening') { break; }
                    elt.data('status', 'opening');
                    elt.submenu.css('overflow', 'hidden');
                    elt.submenu.stop(true, true);
                    slideContainer = jQuery('.sub-menu', elt);
                    if (elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                        slideContainer.animate({marginTop: 0}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                options.status[elt.data('level')] = '';
                                elt.submenu.css('overflow', 'visible');
                                elt.data('status', 'opened');
                            }
                        });

                        elt.submenu.animate({'max-height': elt.submenu.height()}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                jQuery(this).css('max-height', '');
                                options.status[elt.data('level')] = '';
                                elt.submenu.css('overflow', 'visible');
                                elt.data('status', 'opened');
                            }
                        });
                    } else {
                        slideContainer.css('marginLeft', -elt.submenu.width());
                        slideContainer.animate({marginLeft: 0}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                options.status[elt.data('level')] = '';
                                elt.submenu.css('overflow', 'visible');
                                elt.data('status', 'opened');
                            }
                        });

                        elt.submenu.animate({'max-height': elt.submenu.width()}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                options.status[elt.data('level')] = '';
                                elt.submenu.css('overflow', 'visible');
                                elt.data('status', 'opened');
                            }
                        });
                    }
                    break;
                case 'fade':
                    elt.data('status', 'opening');
                    elt.submenu.hide();
                    elt.submenu.stop(true, true);
                    elt.submenu.fadeIn(fxDuration, fxTransition, {
                        complete: function () {
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'opened');
                        }
                    });
                    elt.data('status', 'opened');
                    break;
                case 'show':
                    elt.data('status', 'opening');
                    elt.submenu.hide();
                    elt.submenu.stop(true, true);
                    elt.submenu.show(fxDuration, fxTransition, {
                        complete: function () {
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'opened');
                        }
                    });
                    elt.data('status', 'opened');
                    break;
                case 'scale':
                    elt.data('status', 'opening');
                    if (!elt.hasClass('status') || options.layout.orientation === 'vertical') {
                        elt.submenu.css('margin-left', elt.submenu.width());
                    }
                    elt.submenu.hide();
                    elt.submenu.stop(true, true);
                    elt.submenu.show("scale", {
                        duration: fxDuration, easing: fxTransition, complete: function () {
                            options.status[elt.data('level')] = '';
                            elt.data('status', 'opened');
                        }
                    });
                    elt.data('status', 'opened');
                    break;
                case 'no_effect':
                    options.status[elt.data('level')] = '';
                    elt.data('status', 'opened');
                    break;
                case 'puff':
                    elt.data('status', 'opening');
                    if (!elt.hasClass('level-1') || options.layout.orientation === 'vertical') {
                        elt.submenu.css('margin-left', elt.submenu.width());
                    }
                    elt.submenu.stop(true, true);
                    elt.submenu.show("puff", {
                        duration: fxDuration, easing: fxTransition, complete: function () {
                            options.status[elt.data('level')] = '';
                        }

                    });
                    elt.data('status', 'opened');
                    break;
                case 'open' :
                default:
                    elt.data('status', 'opening');
                    elt.submenu.stop();
                    elt.submenu.css('overflow', 'hidden');
                    if (elt.hasClass('level-1') && options.layout.orientation === 'horizontal') {
                        elt.submenu.animate({'max-height': elt.submenu.height()}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                jQuery(this).css('max-height', '');
                                options.status[elt.data('level')] = '';
                                if (options.effect.type === 'drop_down') {
                                    elt.submenu.css('overflow', 'visible');
                                }
                                elt.data('status', 'opened');
                            }
                        });
                    } else {
                        elt.submenu.animate({'max-width': elt.submenu.width()}, {
                            duration: fxDuration, queue: false, easing: fxTransition, complete: function () {
                                jQuery(this).css('max-width', '');
                                options.status[elt.data('level')] = '';
                                if (options.effect.type === 'drop_down') {
                                    elt.submenu.css('overflow', 'visible');
                                }
                                elt.data('status', 'opened')
                            }
                        })
                    }
                    break
            }
        }
    };
})(jQuery);