//import {parse} from 'date-fns';

module.exports = JeproTools = {
    getRequestLocale : function() {
        let url =  window.location.href;

        if (url.startsWith('http://')) {
            url = url.substring(7);
        } else if (url.startsWith('https://')) {
            url = url.substring(8);
        }
        url = url.substring(url.indexOf('/') + 1);
        return url.substring(0, url.indexOf('/'))
    },

    capitalizeText(value){
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },

    renderNavigationTabs : function(){
        let navTabs = jQuery('.nav-tabs');
        if (navTabs !== undefined && navTabs.length > 0) {
            navTabs.each(function (index, tab) {
                let navTab = jQuery(tab);

                jQuery('.nav-item', navTab).each(function (i, item) {
                    item = jQuery(item);
                    let tabLink = item.find('a.nav-link');
                    let tabTarget = jQuery(tabLink.attr('href'));
                    let newTabTarget;

                    tabTarget.hide();
                    item.on('click', event => {
                        event.preventDefault();
                        let currentTab = jQuery('li.nav-item > a.nav-link.active', navTab);
                        jQuery(currentTab.attr('href')).hide();
                        currentTab.removeClass('active');
                        newTabTarget = jQuery(tabLink.attr('href'));
                        tabLink.addClass('active');
                        newTabTarget.show();
                    });
                });
                let activeTab = jQuery('li.nav-item > a.nav-link.active', navTab);
                jQuery(activeTab.attr('href')).show();
            });
        }
    },

    customInputFileListeners(){
        let customFileInputs = $('.custom-file-input');
        if(customFileInputs !== undefined && customFileInputs.length > 0){
            customFileInputs.each(function(index, elt){
                let customElement = $(elt);
                customElement.on('change', function(event){
                    let inputFile = event.currentTarget;
                    $(inputFile).parent().find('.custom-file-label').html(inputFile.files[0].name);
                });
            });
        }
    },

    customizeSelectField() {
        let selectFieldsItems = $('select');

        if (selectFieldsItems !== undefined) {
            selectFieldsItems.each((index, item) => {
                JeproTools.renderCustomizeSelectField(item);
            });
        }
    },

    renderCustomizeSelectField(item) {
        let field = jQuery(item);
        
        if (field !== undefined) {
            field.removeClass('form-control');
            field.removeClass('form-select');

            let fieldClasses = field.attr('class') !== undefined ? field.attr('class').split(/\s+/) :  '';

            let customSelectWrapper = jQuery('<div>');
            customSelectWrapper.addClass('custom-select-wrapper');
            customSelectWrapper.attr('id', field.attr('id') + '_custom');

            for (let index = 0; index < fieldClasses.length; index++) {
                customSelectWrapper.addClass('custom-' + fieldClasses[index]);
            }

            field.wrap(customSelectWrapper);
            field.addClass('d-none');

            let customSelect = jQuery('<div>');
            customSelect.addClass('custom-select-field');
            field.after(customSelect);

            let customOptionsList = jQuery('<div>');
            customOptionsList.addClass('custom-select-field-options');
            customOptionsList.insertAfter(customSelect);

            let optionGroups = field.children('optgroup');

            if (optionGroups !== undefined && optionGroups.length > 0) {
                let defaultOption = field.children('option');

                if (defaultOption !== undefined) {
                    customSelect.text(defaultOption.eq(0).text());
                }

                optionGroups.each((index, item) => {
                    let optionGroup = jQuery(item);

                    let groupedOptions = jQuery('<div>');
                    groupedOptions.addClass('custom-group-options');

                    optionGroup.children('option').each((ind, elt) => {
                        let element = jQuery(elt);
                        let optionItem = jQuery('<div>');
                        optionItem.addClass('custom-option');
                        optionItem.attr('data-value', element.val());
                        optionItem.text(element.text());
                        optionItem.appendTo(groupedOptions);

                        if (element.is(':selected')) {
                            customSelect.text(optionItem.text());
                        }

                        JeproTools.addSelectOptionHandler(field, customSelect, customOptionsList, optionItem);

                    });

                    let optionGroupElement = jQuery('<div>');
                    optionGroupElement.addClass('custom-option-group')
                        .append(
                            jQuery('<div>').addClass('custom-option-group-title')
                                .text(optionGroup.attr('label'))
                        );

                    optionGroupElement.append(groupedOptions);
                    customOptionsList.append(optionGroupElement);

                });
            } else {
                field.children('option').each((index, elt) => {
                    let element = jQuery(elt);

                    if (index == 0) {
                        customSelect.text(element.text());
                    }

                    let optionItem = jQuery('<div>');
                    optionItem.addClass('custom-option');
                    optionItem.attr('data-value', element.val());

                    let optionIcon = element.data('icon');

                    if (optionIcon !== undefined) {
                        optionItem.addClass('custom-option-flex')
                            .append(
                                jQuery('<div>').addClass('custom-option-icon')
                                    .append(
                                        jQuery('<i>').addClass('custom-option-text')
                                            .addClass(optionIcon)
                                    )
                            ).append(
                                jQuery('<div>').text(element.text())
                            );
                    } else {
                        optionItem.text(element.text());
                    }
                    optionItem.appendTo(customOptionsList);

                    JeproTools.addSelectOptionHandler(field, customSelect, customOptionsList, optionItem);

                    if (element.is(':selected')) {
                        customSelect.text(optionItem.text());
                    }
                });
            }

            customSelect.on('click', event => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                $('div.custom-select-field.active').not(customSelect).each((index, eltItem) => {
                    $(eltItem).removeClass('active').next('div.custom-select-field-options').hide();
                });

                customSelect.toggleClass('active').next('div.custom-select-field-options').toggle();
            });

            $(document).click(() => {
                customSelect.removeClass('active');
                customOptionsList.hide();
            });
        }
    },

    addSelectOptionHandler(field, customField, optionWrapper, option) {
        option.on('click', event => {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();

            customField.text(option.text()).removeClass('active');
            field.val(option.data('value'));
            field.trigger('change');
            optionWrapper.hide();
        });
    },

    bindDatePicker(fromId, toId) {
        let fromDatePicker = jQuery(fromId);
        let toDatePicker = jQuery(toId);

        if (fromDatePicker !== undefined && toDatePicker !== undefined) {
            fromDatePicker.on('change', () => {
                toDatePicker.datepicker("option", "minDate", JeproTools.getPickedDate(this));
            });

            toDatePicker.on('change', () => {
                fromDatePicker.datepicker("option", "maxDate", JeproTools.getPickedDate(this));
            });
        }
    },

    getPickedDate(element) {
        var pickedValue;
        try {
            pickedValue = $.datepicker('mm/dd/yy', element.value);
        } catch (error) {
            pickedValue = null;
        }

        return pickedValue;
    },

    bindPublicAndDeletedSwitch(publishedSwitchedId, deletedSwitchId) {
        let publishedSwitch = jQuery(publishedSwitchedId);
        let publishedSwitchYes = jQuery(publishedSwitchedId + '_1');
        let publishedSwitchNo = jQuery(publishedSwitchedId + '_0');
        let deletedSwitch = jQuery(deletedSwitchId);
        let deletedSwitchYes = jQuery(deletedSwitchId + '_1');
        let deletedSwitchNo = jQuery(deletedSwitchId + '_0');

        deletedSwitchYes.on('change', event => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            if (deletedSwitchYes.is(':checked')) {
                publishedSwitchNo.prop("checked", true);
                publishedSwitch.attr("disabled", "disabled");
                publishedSwitch.children().attr("disabled", "disabled");
            } else {
                publishedSwitch.prop("disabled", false);
                publishedSwitch.children().prop("disabled", false);
            }
        });
        
        publishedSwitchYes.on('change', event => {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            if (publishedSwitchYes.is(':checked')) {
                deletedSwitchNo.prop("checked", true);
                deletedSwitch.attr("disabled", "disabled");
                deletedSwitch.children().attr("disabled", "disabled");
            } else {
                deletedSwitch.prop("disabled", false);
                deletedSwitch.children().prop("disabled", false);
            }
        });
    },

    shouldNotExceedCharLength : (element, maxLength) => {
        element.on('keyup', () => {
            let currentValue = element.val();

            if (!(!currentValue || new String(currentValue).length <= maxLength)) {
                element.val((new String(currentValue)).slice(0, maxLength));
            }
        });
    },

    displayModalNotification : (target) => {
        let modalWrapper = undefined;
        if (target == 'site') {
            modalWrapper = jQuery('#page_modal_content_wrapper');
        }

        if (modalWrapper !== undefined) {
            console.log(modalWrapper)
        }
    },

    validPhoneNumberFormat: function(value) {
        let phoneField = jQuery(value);

        if (phoneField !== undefined) {
            
        }
    }
};
